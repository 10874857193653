import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const ToastContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`;
export const ToastHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  align-items: center;

  & > img {
    width: 22px;
    height: 22px;
  }
`;
export const ToastTitle = styled.span`
  flex: 1;
  color: #364152;
  font-family: 'Inter', Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;
export const ToastText = styled.p`
  font-family: 'Inter', Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: #697586;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
`;
export const ToastFooter = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`;
export const ToastAction = styled(Button)`
  font-family: 'Inter', Helvetica, sans-serif !important;
  border: 1px solid #233a80 !important;
  min-height: unset !important;
  padding: 4px 12px !important;
  border-radius: 4px !important;
  color: #233a80 !important;
  font-size: 14px !important;
  line-height: 1;
  text-transform: none;
`;
