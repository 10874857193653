import { useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { WebSocket } from './hooks/useSocket';
import { SocketProvider } from './pages/DiscussAffiliator/hooks/useSocket';
import { setIsMobile } from './redux/features/screen/screenSlice';
import Router from './routes';
import { useAuth } from './hooks/useAuth';
import { IntegrationProvider } from './hooks/useIntegration';

const App = ({ page }) => {
  const dispatch = useDispatch();
  const { appName, pageTitle } = page;
  const theme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Poppins"',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        main: '#0D52AC',
        dark: '#0049A8',
      },
      success: {
        light: '#2ECC71',
        main: '#03C04A',
        dark: '#008000',
      },
      danger: {
        main: '#FB4141',
      },
    },
  });

  const { user } = useAuth();
  const isLogin = user?.token;
  const isMobile = useMediaQuery('(max-width:900px)');
  const { rule: personalChatRules } = useSelector((state) => state.chat);
  const { openChatFloating, openChatFullScreen, isFloating } =
    personalChatRules;
  const takuRef = useRef();
  const [isTakuExists, setIsTakuExists] = useState(false);

  useEffect(() => {
    const page = pageTitle ? `${pageTitle} - ${appName}` : appName;

    document.title = page;

    return () => {
      document.title = appName;
    };
  }, [pageTitle, appName]);

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const mutationHandler = (mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.classList && node.classList.contains('taku_launcher')) {
          setIsTakuExists(true);
          takuRef.current = node;
        }
      }
    });
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(mutationHandler);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (takuRef.current && isTakuExists) {
      const config = {
        width: 'auto',
        height: 'auto',
        opacity: 1,
        inset: ['auto', isMobile ? '30px' : '80px', '90px', 'auto'],
      };

      if (!isLogin) {
        config.width = 0;
        config.height = 0;
        config.opacity = 0;
      } else if (isLogin && isMobile && openChatFullScreen) {
        config.width = 0;
        config.height = 0;
        config.opacity = 0;
      } else if (isLogin && !isMobile && openChatFullScreen) {
        config.width = 0;
        config.height = 0;
        config.opacity = 0;
      } else if (isLogin && !isMobile && openChatFloating) {
        config.width = 0;
        config.height = 0;
        config.opacity = 0;
      }

      takuRef.current.style.inset = config.inset.join(' ');
      takuRef.current.style.display = config.display;
      takuRef.current.style.height = config.height;
      takuRef.current.style.width = config.width;
      takuRef.current.style.opacity = config.opacity;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    takuRef.current,
    isMobile,
    user?.token,
    openChatFloating,
    isFloating,
    isTakuExists,
    openChatFullScreen,
  ]);

  // Disable Feature Discuss and Chat

  return (
    <ThemeProvider theme={theme}>
      <div id="baleo-seller">
        <WebSocket>
          <SocketProvider>
            <IntegrationProvider>
              <Router />
            </IntegrationProvider>
          </SocketProvider>
        </WebSocket>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.page,
  };
};

export default connect(mapStateToProps)(App);
