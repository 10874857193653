import { get, post } from '../..';
import { integrationConstant } from '../constants/integrationConstant';

export const getIntegrationAccount = async ({ token, app }) => {
  const headers = { Authorization: `Bearer ${token}` };
  return get(integrationConstant?.[app], {}, headers);
};

export const postIntegrationAccount = async ({ token, body, app }) => {
  const headers = { Authorization: `Bearer ${token}` };
  return post(integrationConstant?.[app], body, headers);
};

export const postJubelioSecretKey = async ({ token }) => {
  const headers = { Authorization: `Bearer ${token}` };
  return post(integrationConstant.jubelioKey, {}, headers);
};

export const postBantuDagangSecretKey = async ({ token }) => {
  const headers = { Authorization: `Bearer ${token}` };
  return post(integrationConstant.bantuDagangKey, {}, headers);
};

export const postJubelioImportProduct = async ({ token }) => {
  const headers = { Authorization: `Bearer ${token}` };
  return post(integrationConstant.jubelioImportProduct, {}, headers);
};
