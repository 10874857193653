import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';
import {
  resetNotifData,
  setStopPlayNotifMedia,
} from '../../../redux/features/notification/orderNotificationSlice';
import { setCounterByKey } from '../../../redux/features/sidebar/sidebarSlice';
import { getNumberOfOrders } from '../../../services/Api/Baleomol/module/dashboard';
import {
  ToastAction,
  ToastContainer,
  ToastFooter,
  ToastHeader,
  ToastText,
  ToastTitle,
} from './OrderToast.style';

const LIST_FORMATTER = new Intl.ListFormat('id', {
  style: 'long',
  type: 'conjunction',
});

const TOAST_OPTIONS = {
  position: 'top-right',
  // position: 'bottom-right',
  autoClose: 7000,
  icon: false,
  closeOnClick: false,
};

const ORDER_PATH = {
  reguler: '/order/all/non_bulk/1',
  massal: '/order-bulk/all/1',
  mitra: '/order-mitra/all/1',
};
const TOAST_TITLES = {
  reguler: 'Pesanan',
  massal: 'Pesanan Massal',
  mitra: 'Pesanan Mitra',
};
const ORDER_STATUSES = {
  reguler: { type: 'mp', status: 'paid' },
  massal: { type: 'mp-bulk', status: 'paid' },
  mitra: { type: 'partner', status: 'paid' },
};

const Message = ({ title, text, code = '', type = 'reguler' }) => {
  const orderPath = `${ORDER_PATH[type]}?code=${code}`;

  return (
    <ToastContainer>
      <ToastHeader>
        <img
          alt="Purchase/Package Icon"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzIzM2E4MCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xMjgsMTI5LjA5VjIzMmE4LDgsMCwwLDEtMy44NC0xbC04OC00OC4xOGE4LDgsMCwwLDEtNC4xNi03VjgwLjE4YTgsOCwwLDAsMSwuNy0zLjI1WiIgb3BhY2l0eT0iMC4yIj48L3BhdGg+PHBhdGggZD0iTTIyMy42OCw2Ni4xNSwxMzUuNjgsMThhMTUuODgsMTUuODgsMCwwLDAtMTUuMzYsMGwtODgsNDguMTdhMTYsMTYsMCwwLDAtOC4zMiwxNHY5NS42NGExNiwxNiwwLDAsMCw4LjMyLDE0bDg4LDQ4LjE3YTE1Ljg4LDE1Ljg4LDAsMCwwLDE1LjM2LDBsODgtNDguMTdhMTYsMTYsMCwwLDAsOC4zMi0xNFY4MC4xOEExNiwxNiwwLDAsMCwyMjMuNjgsNjYuMTVaTTEyOCwzMmw4MC4zNCw0NC0yOS43NywxNi4zLTgwLjM1LTQ0Wk0xMjgsMTIwLDQ3LjY2LDc2bDMzLjktMTguNTYsODAuMzQsNDRaTTQwLDkwbDgwLDQzLjc4djg1Ljc5TDQwLDE3NS44MlptMTc2LDg1Ljc4aDBsLTgwLDQzLjc5VjEzMy44MmwzMi0xNy41MVYxNTJhOCw4LDAsMCwwLDE2LDBWMTA3LjU1TDIxNiw5MHY4NS43N1oiPjwvcGF0aD48L3N2Zz4="
        />
        <ToastTitle>{title}</ToastTitle>
      </ToastHeader>
      <ToastText>{text}</ToastText>
      <ToastFooter>
        <ToastAction LinkComponent={Link} to={orderPath}>
          Tampilkan
        </ToastAction>
      </ToastFooter>
    </ToastContainer>
  );
};

const orderToaster = (data, options = {}) =>
  toast.info(
    <Message
      title={data?.title}
      text={data?.text}
      type={data?.type}
      code={data?.code}
    />,
    {
      ...TOAST_OPTIONS,
      ...options,
    },
  );

const OrderToast = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [isAudioUnlocked, setIsAudioUnlocked] = useState(false);
  const { notifMedia, notifData } = useSelector(
    (state) => state?.orderNotification,
  );
  const audio = new Audio(
    'https://is3.cloudhost.id/baleoassestproduction/assets/notification_sound.mp3',
  );

  const unlockAudio = () => {
    if (!isAudioUnlocked) setIsAudioUnlocked(true);
    window.removeEventListener('click', unlockAudio);
    window.removeEventListener('keydown', unlockAudio);
  };

  // eslint-disable-next-line consistent-return
  const playAudio = () => {
    if (!isAudioUnlocked) return false;

    try {
      audio.volume = 0.2;
      audio.play();
    } catch (error) {
      console.error('Cannot execute playSound() due to an error', error);
    } finally {
      dispatch(setStopPlayNotifMedia());
    }
  };

  const launchToast = (data, options = {}) => {
    const codes = LIST_FORMATTER.format(data?.orderCodes);
    const code = data?.orderCodes[0] || 0;

    orderToaster(
      {
        title: `${TOAST_TITLES[data?.orderType]} Baru Telah Masuk!`,
        text: `Ada ${
          data?.orderCodes?.length > 1 ? 'beberapa' : ''
        } pesanan baru dengan Kode ${codes} telah masuk. Segera cek detailnya dan siapkan barangnya untuk dikirim!`,
        code,
        type: data?.orderType || '',
      },
      options,
    );

    dispatch(resetNotifData());
  };

  // eslint-disable-next-line consistent-return
  const fetchOrderNumber = async (type) => {
    if (!ORDER_STATUSES?.[type]) return false;

    try {
      const item = ORDER_STATUSES[type];
      const response = await getNumberOfOrders(
        user?.token,
        item.type,
        item.status,
      );
      if (response?.status === 200 && response?.data?.success) {
        const data = response?.data?.data || {};
        const { numberOfOrder } = data || null;
        dispatch(setCounterByKey({ key: item.type, count: numberOfOrder }));
      }
    } catch (error) {
      console.error('Error while executing fetchOrderNumber()', error);
    }
  };

  useEffect(() => {
    if (user?.token && notifData?.orderCodes) {
      fetchOrderNumber(notifData?.orderType);
      launchToast(notifData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token, notifData]);

  useEffect(() => {
    if (user?.token && notifMedia?.play) playAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token, notifMedia]);

  useEffect(() => {
    window.addEventListener('click', unlockAudio);
    window.addEventListener('keydown', unlockAudio);

    return () => {
      window.removeEventListener('click', unlockAudio);
      window.removeEventListener('keydown', unlockAudio);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="order-notification" />;
};

export default OrderToast;
