/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import { logout as logoutAuth } from '../auth/authSlice';

const INITIAL_STATE = {
  notifMedia: { play: false },
  notifData: {
    orderCodes: undefined,
    orderType: undefined,
  },
};

export const orderNotificationSlice = createSlice({
  name: 'orderNotification',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setPlayNotifMedia: (state) => {
      state.notifMedia = { play: true };
    },
    setStopPlayNotifMedia: (state) => {
      state.notifMedia = { play: false };
    },
    setNotifData: (state, action) => {
      state.notifData = { ...state.notifData, ...action.payload };
    },
    resetNotifData: (state) => {
      state.notifData = { ...INITIAL_STATE.notifData };
    },
    resetOrderNotification: (state) => {
      // eslint-disable-next-line no-unused-vars, no-param-reassign
      state = { ...INITIAL_STATE };
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = { ...INITIAL_STATE }));
  },
});

export const {
  setPlayNotifMedia,
  setStopPlayNotifMedia,
  setNotifData,
  resetNotifData,
  resetOrderNotification,
} = orderNotificationSlice.actions;

export default orderNotificationSlice.reducer;
