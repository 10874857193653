export const backendDomain = `${process.env.REACT_APP_API_URL}/seller`;

export const createEndpoint = (url) => `${backendDomain}${url}`;

export const generateEndpoints = (urls) => {
  const result = {};
  Object.keys(urls).map((item) => {
    result[item] = createEndpoint(urls[item]);

    return true;
  });

  return result;
};
