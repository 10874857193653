import { get } from '../..';
import { dashboardConstant } from '../constants/dashboardConstant';

export const getOmzet = async (token, type, month, year) => {
  const types = ['mp', 'lp', 'affiliate'];

  try {
    if (!types.includes(type))
      throw new Error(
        `Tipe '${type}' tidak valid. Tipe yang tersedia '${types.join("','")}'`,
      );

    const headers = { Authorization: `Bearer ${token}` };
    const query = { type, month, year };

    return get(dashboardConstant.omzet, query, headers);
  } catch (err) {
    // eslint-disable-next-line
    console.error(
      'Error saat mengeksekusi fungsi getOmzet() di modul dashboard: ',
      err,
    );
  }

  return false;
};

export const getBestSellingProducts = async (token, type, month, year) => {
  const types = ['mp', 'lp', 'affiliate'];

  try {
    if (!types.includes(type))
      throw new Error(
        `Tipe '${type}' tidak valid. Tipe yang tersedia '${types.join("','")}'`,
      );

    const headers = { Authorization: `Bearer ${token}` };
    const query = { type, month, year };
    return get(dashboardConstant.bestSellingProduct, query, headers);
  } catch (err) {
    // eslint-disable-next-line
    console.error(
      'Error saat mengeksekusi fungsi getBestSellingProducts() di modul dashboard: ',
      err,
    );
  }

  return false;
};

export const getOnGoingFund = async (token, type, status) => {
  const availableStatuses = [
    {
      type: 'mp',
      statuses: ['on_shipping', 'more_than_9_days', 'received', 'complaint'],
    },
    {
      type: 'lp',
      statuses: ['on_shipping', 'success', 'claim', 'claim_disbursed'],
    },
    {
      type: 'affiliate',
      statuses: ['on_shipping', 'more_than_9_days', 'complaint'],
    },
  ];
  const types = availableStatuses.map((item) => item.type);

  try {
    const selectedTypes = availableStatuses.filter(
      (item) => item.type === type,
    );

    if (!types.includes(type))
      throw new Error(
        `Tipe '${type}' tidak valid. Tipe yang tersedia '${types.join("','")}'`,
      );

    const { statuses } = selectedTypes[0];

    if (!statuses.includes(status))
      throw new Error(
        `Pilihan status '${status}' untuk tipe '${type}' tidak valid. Status yang tersedia '${statuses.join(
          "','",
        )}'`,
      );

    const headers = { Authorization: `Bearer ${token}` };
    const query = { type, status };

    return get(dashboardConstant.onGoingFund, query, headers);
  } catch (err) {
    // eslint-disable-next-line
    console.error(
      'Error saat mengeksekusi fungsi getOnGoingFund() di modul dashboard: ',
      err,
    );
  }

  return false;
};

export const getNumberOfOrders = async (token, type, status) => {
  const availableStatuses = [
    {
      type: 'mp',
      statuses: ['paid', 'need-to-ship', 'complaint'],
    },
    {
      type: 'mp-bulk',
      statuses: ['paid', 'need-to-ship', 'complaint'],
    },
    {
      type: 'lp',
      statuses: ['paid', 'need-to-ship', 'process'],
    },
    {
      type: 'affiliate',
      statuses: ['paid', 'need-to-ship', 'process'],
    },
    {
      type: 'partner',
      statuses: ['paid', 'need-to-ship', 'process'],
    },
  ];
  const types = availableStatuses.map((item) => item.type);

  try {
    const selectedTypes = availableStatuses.filter(
      (item) => item.type === type,
    );

    if (!types.includes(type))
      throw new Error(
        `Tipe '${type}' tidak valid. Tipe yang tersedia '${types.join("','")}'`,
      );

    const { statuses } = selectedTypes[0];

    if (!statuses.includes(status))
      throw new Error(
        `Pilihan status '${status}' untuk tipe '${type}' tidak valid. Status yang tersedia '${statuses.join(
          "','",
        )}'`,
      );

    const headers = { Authorization: `Bearer ${token}` };
    const query = { status, type };

    return get(dashboardConstant.numberOfOrders, query, headers);
  } catch (err) {
    // eslint-disable-next-line
    console.error(
      'Error saat mengeksekusi fungsi getOnGoingFund() di modul dashboard: ',
      err,
    );
  }

  return false;
};

export const getChartOrders = async (token, type, timeUnit) => {
  const types = ['mp', 'lp', 'affiliate'];
  const units = ['month', 'year'];

  try {
    if (!types.includes(type))
      throw new Error(
        `Tipe '${type}' tidak valid. Tipe yang tersedia '${types.join("','")}'`,
      );

    if (!units.includes(timeUnit))
      throw new Error(
        `Unit '${timeUnit}' tidak valid. Tipe yang tersedia '${units.join(
          "','",
        )}'`,
      );

    const headers = { Authorization: `Bearer ${token}` };
    const query = { type, timeUnit };

    return get(dashboardConstant.chartOrder, query, headers);
  } catch (err) {
    // eslint-disable-next-line
    console.error(
      'Error saat mengeksekusi fungsi getOnGoingFund() di modul dashboard: ',
      err,
    );
  }

  return false;
};
