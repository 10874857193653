/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
// import iSellerLogo from '../assets/images/logos/integratedApps/iseller-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BantuDagangLogo from '../assets/images/logos/integratedApps/bantu-dagang-logo.png';
import JubelioLogo from '../assets/images/logos/integratedApps/jubelio-logo.png';
import IntegrationTos from '../components/organisms/IntegrationTos/IntegrationTos';
import { getIntegrationAccount } from '../services/Api/Baleomol/module/integration';
import { useAuth } from './useAuth';
import { setIntegration } from '../redux/features/feature/featureSlice';

const APP_LIST = [
  // {
  //   name: 'Jubelio',
  //   site: 'https://jubelio.com/',
  //   id: 'jubelio',
  //   image: JubelioLogo,
  //   isNew: false,
  //   isConnected: false,
  //   logoHeight: '48px',
  //   active: true,
  // },
  {
    name: 'Bantu Dagang',
    site: 'https://bantudagang.com/',
    id: 'bantu-dagang',
    image: BantuDagangLogo,
    isNew: false,
    isConnected: false,
    logoHeight: '48px',
    active: true,
  },
  // {
  //   name: 'iSeller',
  //   site: 'https://www.isellercommerce.com/',
  //   id: 'iseller',
  //   image: iSellerLogo,
  //   isNew: true,
  //   isConnected: false,
  //   logoHeight: '36px',
  //   active: false,
  // },
];

const INITIAL_LOADING_STATE_VALUE = {
  jubelio: {
    getData: false,
    postData: false,
    generateCode: false,
    getCode: false,
    postImport: false,
  },
  'bantu-dagang': {
    getData: false,
    postData: false,
    generateCode: false,
    getCode: false,
  },
};

const IntegrationContext = createContext();

export const IntegrationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { integration } = useSelector((state) => state.feature);
  const [list, setList] = useState([...APP_LIST]);
  const [data, setData] = useState({});
  const [openTosModal, setOpenTosModal] = useState(false);
  const [loading, setLoading] = useState({ ...INITIAL_LOADING_STATE_VALUE });
  const { user } = useAuth();

  const tosSubmitHandler = () => {
    setOpenTosModal(false);
    dispatch(
      setIntegration({
        key: 'jubelio',
        value: {
          isTermsAccepted: true,
        },
      }),
    );
  };

  const tosCloseHandler = () => {
    setOpenTosModal(false);
  };

  const insertIntoData = (id, data) => {
    const time = new Date().getTime();
    setData((prev) => {
      return {
        ...prev,
        [id]: {
          lastUpdate: time,
          ...data,
        },
      };
    });
  };

  const setConnected = useCallback(
    (id, isConnected) => {
      const index = list.findIndex((item) => item.id === id);
      if (index >= 0) {
        dispatch(
          setIntegration({
            key: id,
            value: {
              isAccountLinked: true,
              isTermsAccepted: true,
            },
          }),
        );
        setList((prev) => {
          const result = [...prev];
          result[index].isConnected = isConnected;
          return [...result];
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, integration],
  );

  const fetchData = useCallback(async (appId) => {
    const app = list.find((item) => item.id === appId);
    if (!app) {
      const message = `Aplikasi integrasi dengan id '${appId}' tidak ditemukan!`;
      toast.error(message);
      throw new Error(message);
    }

    const toastId = toast.loading('Memuat data...');
    setLoading((prev) => {
      return {
        ...prev,
        [appId]: {
          ...prev[appId],
          getData: true,
        },
      };
    });

    try {
      const res = await getIntegrationAccount({
        token: user?.token,
        app: appId,
      });

      if (res.status === 200 && res.data?.success) {
        toast.dismiss(toastId);
        const { data: appData } = res.data;
        insertIntoData(appId, appData);

        dispatch(
          setIntegration({
            key: appId,
            value: {
              isAccountLinked: true,
              isTermsAccepted: true,
            },
          }),
        );
      } else {
        toast.update(toastId, {
          type: 'error',
          render:
            res?.data?.message ||
            `Terjadi kesalahan saat memuat data ${app.name}. Coba beberapa saat lagi!`,
          autoClose: 3000,
          isLoading: false,
        });

        dispatch(
          setIntegration({
            key: appId,
            value: {
              isAccountLinked: false,
            },
          }),
        );
      }
    } catch (error) {
      console.error({ 'Error while executing fetchData()': error });
      toast.update(toastId, {
        type: 'error',
        render:
          error?.response?.data?.message ||
          error?.message ||
          `Terjadi kesalahan saat memuat data ${app.name}. Coba beberapa saat lagi!`,
        autoClose: 3000,
        isLoading: false,
      });

      dispatch(
        setIntegration({
          key: appId,
          value: {
            isAccountLinked: false,
          },
        }),
      );
    } finally {
      setLoading((prev) => {
        return {
          ...prev,
          [appId]: {
            ...prev[appId],
            getData: false,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllData = async () => {
    const activeAppIds = list
      .filter(({ active }) => active)
      .map(({ id }) => id);

    console.warn({ masuk: '' });

    try {
      const responses = await Promise.allSettled(
        activeAppIds.map((id) =>
          getIntegrationAccount({
            token: user?.token,
            app: id,
          }),
        ),
      );

      responses.forEach((res, i) => {
        const id = activeAppIds[i];
        const resValue = res?.value || {};
        if (res.status === 'fulfilled' && resValue.data?.success) {
          const { data: appData } = resValue.data;
          let connected = true;

          if (id === 'bantu-dagang') connected = appData?.status === 'active';

          insertIntoData(id, appData);
          setConnected(id, connected);
        }
      });
    } catch (err) {
      console.error({ 'Error while executing fetchAllData()': err });
    } finally {
      // fix me
    }
  };

  const value = useMemo(() => {
    return {
      list,
      data,
      setList,
      setData,
      insertIntoData,
      setConnected,
      fetchData,
      loading,
      setLoading,
      integration,
      setIntegration,
      openTosModal,
      setOpenTosModal,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, data, setConnected, fetchData, loading, integration, openTosModal]);

  useEffect(() => {
    let timeoutId = null;

    if (!Object.keys(data).length && user?.token) {
      timeoutId = setTimeout(() => {
        fetchAllData();
      }, 500);
    }

    if (!user?.token) {
      timeoutId = setTimeout(() => {
        setData({});
      }, 500);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, data, user]);

  return (
    <IntegrationContext.Provider value={value}>
      {children}

      <IntegrationTos
        open={openTosModal}
        onSubmit={tosSubmitHandler}
        onClose={tosCloseHandler}
      />
    </IntegrationContext.Provider>
  );
};

const useIntegration = () => useContext(IntegrationContext);

export default useIntegration;
