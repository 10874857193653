/* eslint-disable no-unused-vars */
import { Slide } from '@mui/material';
import { X, CheckCircle } from '@phosphor-icons/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  CheckboxContainer,
  CheckboxLabel,
  CloseButton,
  ConfirmButton,
  Container,
  ContentContainer,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  TermContent,
  TermTitle,
  Title,
} from './IntegrationTos.style';
import {
  INITIAL_DISABLED_STATE,
  TERMS_OF_SERVICE,
} from './IntegrationTos.const';

export const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />;
});

const IntegrationTos = ({ open, onClose, onSubmit }) => {
  const title = 'Syarat dan Ketentuan (Term of Service) Pengelolaan Akun';
  const [disabled, setDisabled] = useState({ ...INITIAL_DISABLED_STATE });
  const [isChecked, setIsChecked] = useState(false);
  const onCloseHandler = () => {
    if (typeof onClose === 'function') onClose();
  };
  const onSubmitHandler = () => {
    if (typeof onSubmit === 'function') onSubmit(true);
  };
  const contentScrollHandler = (e) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;

    if (offsetHeight + scrollTop >= scrollHeight && disabled.checkbox) {
      setDisabled((prev) => {
        return {
          ...prev,
          checkbox: false,
        };
      });
    }
  };

  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current) {
      const { offsetHeight, scrollHeight } = contentRef.current;
      if (scrollHeight - 10 > offsetHeight)
        setDisabled((prev) => {
          return {
            ...prev,
            checkbox: true,
          };
        });
      else
        setDisabled((prev) => {
          return {
            ...prev,
            checkbox: false,
          };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  useEffect(() => {
    setDisabled((prev) => {
      return {
        ...prev,
        submit: !isChecked,
      };
    });
  }, [isChecked]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onCloseHandler}
      aria-describedby="alert-dialog-slide-description">
      <DialogHeader>
        <Title>{title}</Title>

        <CloseButton onClick={onCloseHandler}>
          <X />
        </CloseButton>
      </DialogHeader>
      <DialogBody>
        <ContentContainer
          gap="16px"
          flexDirection="column"
          flexWrap="nowrap"
          ref={contentRef}
          onScroll={contentScrollHandler}>
          {TERMS_OF_SERVICE.map((row, index) => (
            <Container
              gap="8px"
              flexDirection="column"
              key={`Konten ${index + 1}`}>
              <TermTitle>{row.title}</TermTitle>
              <TermContent>
                {row.content}

                {row?.content_list && (
                  <>
                    <br />
                    <br />
                    <ul>
                      {row.content_list.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </>
                )}
              </TermContent>
            </Container>
          ))}
        </ContentContainer>
      </DialogBody>
      <DialogFooter>
        <CheckboxContainer>
          <Checkbox
            value="checked"
            disabled={disabled.checkbox}
            id="tos-checkbox"
            onChange={() => setIsChecked((prev) => !prev)}
            checked={isChecked}
            aria-labelledby="tos-checkbox-label"
          />
          <CheckboxLabel
            disabled={disabled.checkbox}
            id="tos-checkbox-label"
            aria-label="tos-checkbox"
            htmlFor="tos-checkbox">
            Saya telah membaca dan menyetujui{' '}
            <strong>Syarat dan Ketentuan</strong>
          </CheckboxLabel>
        </CheckboxContainer>

        <ConfirmButton
          disabled={disabled.submit}
          onClick={onSubmitHandler}
          startIcon={<CheckCircle weight="fill" />}
          color="primary">
          Hubungkan Akun
        </ConfirmButton>
      </DialogFooter>
    </Dialog>
  );
};

export default IntegrationTos;
