import styled from '@emotion/styled';
import {
  IconButton as MuiIconButton,
  Button as MuiButton,
  Checkbox as MuiCheckbox,
  Dialog as MuiDialog,
} from '@mui/material';

export const Dialog = styled(MuiDialog)`
  font-family: 'Inter', Helvetica, sans-serif;

  * {
    font-family: inherit !important;
  }

  .MuiPaper-root {
    max-width: 900px;
    height: calc(100dvh - 32px);
    margin: 16px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #202939;
`;

export const DialogHeader = styled.div`
  border-bottom: 1px solid #e3e8ef;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16px;
`;

export const DialogBody = styled.div`
  width: 100%;
  padding: 16px 24px;
  flex: 1;
  overflow: hidden;
`;

export const BodyContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #e3e8ef;
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxContainer = styled.div`
  width: fit-content;
  max-width: 100%;
  display: flex;
  gap: 2px;
  flex-wrap: nowrap;
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  height: 24px;
  width: 24px;

  .MuiCheckbox-root {
    --svg-color: #cdd5df;

    &.Mui-checked {
      --svg-color: #0068e1;
    }

    &. svg.MuiSvgIcon-root {
      fill: var(--svg-color);
    }
  }
`;

export const CloseButton = styled(MuiIconButton)`
  padding: 4px;
  font-size: 18px;
`;

export const CheckboxLabel = styled.span`
  color: #4b5565;
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  flex: 1;

  ${(props) => (props.disabled ? 'opacity: .55' : '')}
`;

export const ConfirmButton = styled(MuiButton)`
  width: 260px;
  height: 48px;
  padding: 12px 20px 12px 20px;
  gap: 8px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  text-transform: none;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  ${(props) => {
    const config = {
      bgColor: '#ffffff',
      borderColor: '#CDD5DF',
      textColor: '#364152',
    };

    if (props?.color === 'primary') {
      config.bgColor = '#0068E1';
      config.borderColor = '#0068E1';
      config.textColor = '#ffffff';
    }

    if (props?.disabled) {
      config.bgColor = '#E3E8EF';
      config.borderColor = '#E3E8EF';
      config.textColor = '#9AA4B2';
    }

    return `
      background-color: ${config.bgColor} !important;
      border: 1px solid ${config.borderColor};
      color: ${config.textColor};
    `;
  }}

  .MuiButton-icon svg {
    font-size: 18px;
    ${(props) => props.loading && 'animation: 1.5s rotate infinite;'}
  }
`;

export const Container = styled.div`
  display: flex;

  ${(props) => {
    let config = {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: '24px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
      padding: 0,
    };

    config = Object.fromEntries(
      Object.entries(config).map(([key, val]) => {
        let value = val;
        if (props?.[key]) value = props?.[key];
        return [key, value];
      }),
    );

    return `
      flex-direction: ${config.flexDirection};
      gap: ${config.gap};
      justify-content: ${config.justifyContent};
      align-items: ${config.alignItems};
      width: ${config.width};
      padding: ${config.padding};

      @media screen and (min-width: 768px) {
        flex-wrap: ${config.flexWrap};
      }
    `;
  }}
`;

export const TermTitle = styled.span`
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

export const TermContent = styled.p`
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  paadding: 0;
  margin: 0;

  ul {
    margin: 0;
    padding-left: 32px;
  }
`;

export const ContentContainer = styled(Container)`
  max-height: 100% !important;
  overflow-y: auto;
`;
