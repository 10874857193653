import { generateEndpoints } from '.';

const urls = {
  omzet: '/dashboard/omzet',
  bestSellingProduct: '/dashboard/best-selling-product',
  onGoingFund: '/dashboard/on-going-fund',
  numberOfOrders: '/dashboard/number-of-order',
  chartOrder: '/dashboard/chart-order',
};

export const dashboardConstant = generateEndpoints(urls);
