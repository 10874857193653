/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useJwt } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { logout, reset } from '../redux/features/auth/authSlice';
import { resetIntegration } from '../redux/features/feature/featureSlice';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const decodedToken = useJwt(user?.token);

  const logoutUser = useCallback(() => {
    dispatch(logout());
    dispatch(reset());
    dispatch(resetIntegration());
    <Navigate to="/" />;
  }, [dispatch]);

  const value = useMemo(() => {
    return { user, logout: logoutUser };
  }, [user, logoutUser]);

  useEffect(() => {
    if (decodedToken?.decodedToken?.exp < Math.floor(Date.now() / 1000)) {
      logoutUser();
    }
  }, [decodedToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
