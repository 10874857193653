export const INITIAL_STATE = {
  watchedVideos: {
    marketplace: false,
    mitra: false,
    pageBuilder: false,
    baleomolAffiliate: false,
    supportAffilio: false,
    maklonProduct: false,
    supportCod: false,
  },
  integration: {
    jubelio: {
      isTermsAccepted: false,
      isAccountLinked: false,
    },
  },
};
