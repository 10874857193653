export const TERMS_OF_SERVICE = [
  {
    title: '1. Penerimaan Syarat',
    content:
      'Dengan memberikan akses ke akun Anda, Anda menyetujui dan menerima syarat dan ketentuan berikut. Jika Anda tidak setuju dengan ketentuan ini, harap tidak memberikan akses ke akun Anda.',
  },
  {
    title: '2. Otorisasi Pengelolaan Akun',
    content:
      'Dengan memberikan kredensial atau akses ke akun Anda, Anda memberikan otorisasi kepada PT Baleo Mol Indonesia untuk mengelola, memperbarui, dan memodifikasi akun atas nama Anda. Ini termasuk namun tidak terbatas pada:',
    content_list: [
      'Melihat list produk dan stok',
      'Membuat pesanan',
      'Mengupdate pesanan',
    ],
  },
  {
    title: '3. Tanggung Jawab Pengguna',
    content: 'Anda setuju bahwa:',
    content_list: [
      'Anda adalah pemilik akun atau memiliki hak penuh untuk memberikan akses pengelolaan.',
      'Anda tidak akan menuntut PT Baleo Mol Indonesia atas perubahan yang dilakukan pada akun selama masih dalam lingkup otorisasi pengelolaan.',
    ],
  },
  {
    title: '4. Batasan Tanggung Jawab',
    content:
      'PT Baleo Mol Indonesia akan berusaha sebaik mungkin untuk mengelola akun Anda secara profesional. Namun, Anda menyetujui bahwa PT Baleo Mol Indonesia tidak bertanggung jawab atas:',
    content_list: [
      'Kehilangan data.',
      'Penangguhan, pembatasan, atau pemblokiran akun akibat aturan atau tindakan platform.',
      'Akses tidak sah oleh pihak ketiga yang terjadi akibat pemberian kredensial.',
    ],
  },
  {
    title: '5. Pencabutan Akses',
    content:
      'Anda dapat mencabut akses PT Baleo Mol Indonesia ke akun Anda kapan saja dengan menghapus dari panel seller di integrasi jubelio. Namun, segala tindakan yang dilakukan sebelum pencabutan tidak menjadi tanggung jawab PT Baleo Mol Indonesia.',
  },
  {
    title: '6. Kerahasiaan',
    content:
      'PT Baleo Mol Indonesia setuju untuk menjaga kerahasiaan semua kredensial akun dan informasi sensitif yang diberikan dan tidak akan membagikannya dengan pihak ketiga, kecuali diwajibkan oleh hukum atau dengan persetujuan eksplisit dari Anda.',
  },
  {
    title: '7. Penghentian',
    content:
      'PT Baleo Mol Indonesia berhak untuk menghentikan layanan pengelolaan jika diduga terjadi penyalahgunaan, aktivitas ilegal, atau pelanggaran terhadap syarat dan ketentuan ini.',
  },
  {
    title: '8. Perubahan Syarat',
    content:
      'PT Baleo Mol Indonesia berhak untuk mengubah syarat dan ketentuan ini kapan saja. Perubahan dapat dilakukan tanpa pemberitahuan dan penggunaan layanan pengelolaan akun yang berkelanjutan akan dianggap sebagai penerimaan terhadap syarat yang diperbarui.',
  },
];

export const INITIAL_DISABLED_STATE = {
  checkbox: false,
  submit: false,
};
