/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './const';

export const featureSlice = createSlice({
  name: 'feature',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setWatchedVideos: (state, action) => {
      state.watchedVideos = {
        ...state.watchedVideos.baleomolAffiliate,
        ...action,
      };
    },
    resetWatchedVideos: (state) => {
      state.watchedVideos = {
        ...INITIAL_STATE.watchedVideos,
      };
    },
    setIntegration: (state, action) => {
      const { key, value } = action.payload;

      state.integration = {
        ...state.integration,
        [key]: {
          ...state.integration[key],
          ...value,
        },
      };
    },
    resetIntegration: (state) => {
      state.integration = {
        ...INITIAL_STATE.integration,
      };
    },
  },
});

export const {
  setWatchedVideos,
  resetWatchedVideos,
  setIntegration,
  resetIntegration,
} = featureSlice.actions;

export default featureSlice.reducer;
