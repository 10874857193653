import axios from 'axios';

export const apiClient = async ({ method, url, data, headers, axiosOpts }) => {
  const forbiddenKeys = ['method', 'url', 'data', 'headers'];
  if (
    axiosOpts &&
    Object.keys(axiosOpts).some((item) => forbiddenKeys.includes(item))
  )
    throw new Error(
      `Key pada axiosOpts tidak valid. ${forbiddenKeys.join(
        ', ',
      )} tidak boleh ada di dalam axiosOpts`,
    );

  let options = {
    method,
    url,
    data,
    headers,
  };

  if (axiosOpts) options = { ...options, ...axiosOpts };

  const response = await axios(options);

  return response;
};

export const get = async (url, query, headers = {}, cancelToken = null) => {
  const queryParams = query
    ? `?${Object.keys(query)
        .map((item) => `${item}=${query[item]}`)
        .join('&')}`
    : '';
  const options = {
    method: 'get',
    url: `${url}${queryParams}`,
    headers,
  };

  if (cancelToken) options.axiosOpts = { cancelToken };

  const result = await apiClient(options);

  return result;
};

export const post = async (url, data, headers = {}) => {
  const options = {
    method: 'post',
    url,
    data,
    headers,
  };
  const result = await apiClient(options);

  return result;
};

export const remove = async (url, data, headers = {}) => {
  const options = {
    method: 'delete',
    url,
    data,
    headers,
  };
  const result = await apiClient(options);

  return result;
};

export const put = async (url, data, headers = {}) => {
  const options = {
    method: 'put',
    url,
    data,
    headers,
  };
  const result = await apiClient(options);

  return result;
};

export const patch = async (url, data, headers = {}) => {
  const options = {
    method: 'patch',
    url,
    data,
    headers,
  };
  const result = await apiClient(options);

  return result;
};
