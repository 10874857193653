/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import { logout as logoutAuth } from '../auth/authSlice';

/* eslint no-param-reassign: "error" */

// Define State
const initialState = {
  data: {
    counter: [
      {
        key: 'mp',
        count: 0,
      },
      {
        key: 'mp-bulk',
        count: 0,
      },
      {
        key: 'lp',
        count: 0,
      },
      {
        key: 'affiliate',
        count: 0,
      },
      {
        key: 'partner',
        count: 0,
      },
      {
        key: 'discuss-marketplace',
        count: 0,
      },
    ],
  },
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    // eslint-disable-next-line consistent-return
    setCounterByKey: (state, action) => {
      if (!action.payload?.key) return false;
      const index = state.data.counter.findIndex(
        (item) => item.key === action.payload.key,
      );
      if (index < 0) return false;
      state.data.counter[index].count = action.payload.count;
    },
    setCounter: (state, action) => {
      state.data.counter = action.payload;
    },
    resetCounter: (state) => {
      state.data = initialState;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
    builder.addCase(logoutAuth, (state) => (state = initialState));
  },
});

export const { setCounterByKey, setCounter, resetCounter } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
